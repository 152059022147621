import React from "react";
import {TwitterShareButton, TwitterIcon} from "react-share";
import ShareIcon from "./ShareIcon";
import EvangeliSocialShareData from "../../models/evangeli/EvangeliSocialShareData";

interface TwitterShareProps {
  evangeliSocialShareData: EvangeliSocialShareData;
}

const TwitterShare: React.FC<TwitterShareProps> = (
  props: TwitterShareProps,
) => {

  const Icon = ShareIcon(TwitterIcon);

  function getBody() {

    const social_text = props.evangeliSocialShareData.comments[0] && props.evangeliSocialShareData.comments[0].social_text
      ?
      props.evangeliSocialShareData.comments[0].social_text
      :
      props.evangeliSocialShareData.gospel_title;

    const verse = props.evangeliSocialShareData.comments[0] && props.evangeliSocialShareData.comments[0].verse
      ?
      props.evangeliSocialShareData.comments[0].verse + "\n\n"
      :
      '';

    return social_text + "\n\n" + verse;
  }

  return (
    <TwitterShareButton
      url={props.evangeliSocialShareData.url}
      title={getBody()}
      related={["@evangeli_net_es"]}
    >
        <Icon />
    </TwitterShareButton>
  );
}

export default TwitterShare;
