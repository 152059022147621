import axios from 'axios';

const EvangeliCall = {
  async getSocialShareData(locale: string, liturgical_day: string, service_type: 'contempla' | 'master' | 'family') {
    const url = `/api/evangeli/day/${liturgical_day}/${locale}/${service_type}`;
    const response = await axios.get(url);
    return response.data;
  }
}

export default EvangeliCall;
