import React from 'react';
import Calendar from 'react-calendar';
import moment from "moment";
import 'moment/min/locales.min';

interface CalendarWidgetProps {
  locale: string;
  currentDate: Date;
  minDate: Date;
  maxDate: Date;
  onChange: Function;
}

const CalendarWidget: React.FC<CalendarWidgetProps> = (
  props: CalendarWidgetProps,
) => {
  return (
    <Calendar
      locale={props.locale}
      value={props.currentDate}
      minDate={props.minDate}
      maxDate={props.maxDate}
      showNeighboringMonth={false}
      prev2Label={null}
      next2Label={null}
      formatMonthYear={(locale, date) => {
        return moment(date)
          .locale(locale)
          .format('MMMM YYYY');
      }}
      onChange={props.onChange}
      tileClassName={
        ({ activeStartDate, date, view }) => view === 'month' && date.getDay() === 6 ? 'saturday' : null
      }
    />
  );
}

export default CalendarWidget;
