import React from 'react';
import CalendarWidget from "../components/liturgical/CalendarWidget";
import moment from "moment";

interface CalendarSelectorProps {
  locale?: string | null;
  currentDate?: string | null;
  historyUrl: string;
  viewDateUrl: string;
  firstAvailableDate?: string | null;
  lastAvailableDate?: string | null;
}

const CalendarSelector: React.FC<CalendarSelectorProps> = (
  props: CalendarSelectorProps,
) => {
  const defaultLocale = 'en';
  const today = new Date();
  const maxDaysBeforeToday = 7;
  const maxDaysAfterToday = 13;

  const selectDayHandler = (date: Date) => {
    const d = moment(date)
    const dayValue = d.format('YYYY-MM-DD');

    if(dayValue === '') {
      window.location.href = props.historyUrl;
    } else {
      window.location.href = props.viewDateUrl.replace('YYYY-MM-DD', dayValue);
    }
  }

  function daysInMilliseconds(days: number) {
    return 1000 * 60 * 60 * 24 * days;
  }

  function getMinDate() {
    return props.firstAvailableDate
      ?
        new Date(props.firstAvailableDate)
      :
        new Date(today.getTime() - (daysInMilliseconds(maxDaysBeforeToday)))
      ;
  }

  function getMaxDate() {
    return props.lastAvailableDate
      ?
        new Date(props.lastAvailableDate)
      :
        new Date(today.getTime() + (daysInMilliseconds(maxDaysAfterToday)))
      ;
  }

  return (
    <CalendarWidget
      locale={props.locale ?? defaultLocale}
      currentDate={props.currentDate ? new Date(props.currentDate) : today}
      minDate={getMinDate()}
      maxDate={getMaxDate()}
      onChange={selectDayHandler}
    />
  );
}

export default CalendarSelector;
