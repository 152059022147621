import React, {useEffect, useState} from "react";
import EmailShare from "../components/share/EmailShare";
import FacebookShare from "../components/share/FacebookShare";
import styled from "styled-components";
import TwitterShare from "../components/share/TwitterShare";
import WhatsappShare from "../components/share/WhatsappShare";
import EvangeliCall from "../api/evangeli/Evangeli";
import EvangeliSocialShareData from "../models/evangeli/EvangeliSocialShareData";

interface ShareOptionsProps {
  locale: string;
  evangeli_type: 'contempla' | 'master' | 'family';
  matricula: string;
}

const ShareOptions: React.FC<ShareOptionsProps> = (
  props: ShareOptionsProps,
) => {
  const FlexBox = styled.div`
    display: flex;
    justify-content: space-evenly;
  `;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [evangeliSocialShareData, setEvangeliSocialShareData] = useState<EvangeliSocialShareData|null>(null);

  useEffect(() => {
    const fetchEvangeliData = () => {
      EvangeliCall.getSocialShareData(props.locale, props.matricula, props.evangeli_type).then(
        (evangeliSocialShareData: EvangeliSocialShareData) => {
          setEvangeliSocialShareData(evangeliSocialShareData);
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
        }
      );
    }

    fetchEvangeliData();
  }, [props, setEvangeliSocialShareData, setIsLoading])

  return (
    <FlexBox>
      {(isLoading || evangeliSocialShareData === null)
      ?
        (
          <div className="loader">Loading...</div>
        )
        :
        (
          <>
            <EmailShare evangeliSocialShareData={evangeliSocialShareData} />
            <FacebookShare evangeliSocialShareData={evangeliSocialShareData} />
            <TwitterShare evangeliSocialShareData={evangeliSocialShareData} />
            <WhatsappShare evangeliSocialShareData={evangeliSocialShareData} />
          </>
        )
      }
    </FlexBox>
  );
}

export default ShareOptions;
