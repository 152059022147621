import React from "react";
import {FacebookShareButton, FacebookIcon} from "react-share";
import ShareIcon from "./ShareIcon";
import EvangeliSocialShareData from "../../models/evangeli/EvangeliSocialShareData";

interface FacebookShareProps {
  evangeliSocialShareData: EvangeliSocialShareData;
}

const FacebookShare: React.FC<FacebookShareProps> = (
  props: FacebookShareProps,
) => {

  const Icon = ShareIcon(FacebookIcon);

  function getBody() {

    const social_text = props.evangeliSocialShareData.comments[0] && props.evangeliSocialShareData.comments[0].social_text
      ?
      props.evangeliSocialShareData.comments[0].social_text
      :
      props.evangeliSocialShareData.gospel_title;

    const verse = props.evangeliSocialShareData.comments[0] && props.evangeliSocialShareData.comments[0].verse
      ?
      props.evangeliSocialShareData.comments[0].verse + "\n\n"
      :
      '';

    return social_text + "\n\n" + verse;
  }

  return (
    <FacebookShareButton
      url={props.evangeliSocialShareData.url}
      quote={getBody()}>
        <Icon />
    </FacebookShareButton>
  );
}

export default FacebookShare;
