import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import CalendarSelector from "./containers/CalendarSelector";
import ShareOptions from "./containers/ShareOptions";

//Polyfill to enable date formating on old browsers
if (!global.Intl) {
  require('intl');
  require('intl/locale-data/jsonp/en.js');
}

function ComponentWrapper(Component) {
  return (
    <React.StrictMode>
      <Suspense fallback={<></>}>
        {Component}
      </Suspense>
    </React.StrictMode>
  );
}

const calendar_selector = document.getElementById('calendar_selector');
if (calendar_selector) {
  ReactDOM.render(
    ComponentWrapper(
      <CalendarSelector
        locale={calendar_selector.getAttribute('data-locale')}
        currentDate={calendar_selector.getAttribute('data-current-date')}
        historyUrl={calendar_selector.getAttribute('data-history-url') ?? ''}
        viewDateUrl={calendar_selector.getAttribute('data-view-date-url') ?? ''}
        firstAvailableDate={calendar_selector.getAttribute('data-first-available-date') ?? ''}
        lastAvailableDate={calendar_selector.getAttribute('data-last-available-date') ?? ''}
      />
    ),
    document.getElementById('calendar_selector'),
  );
}

const share_buttons = document.getElementById('share_buttons');
if (share_buttons) {
  const locale = share_buttons.getAttribute('data-locale')
  const evangeliType = share_buttons.getAttribute('data-evangeli-type')
  const evangeliMatricula = share_buttons.getAttribute('data-matricula')

  if (locale && (evangeliType === 'contempla' || evangeliType === 'master' || evangeliType === 'family') && evangeliMatricula) {
    ReactDOM.render(
      ComponentWrapper(
        <ShareOptions
          locale={locale}
          evangeli_type={evangeliType}
          matricula={evangeliMatricula}
        />
      ),
      document.getElementById('share_buttons'),
    );
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
