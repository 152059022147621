import React from "react";
import {EmailIcon, EmailShareButton} from "react-share";
import ShareIcon from "./ShareIcon";
import EvangeliSocialShareData from "../../models/evangeli/EvangeliSocialShareData";

interface EmailShareProps {
  evangeliSocialShareData: EvangeliSocialShareData;
}

const EmailShare: React.FC<EmailShareProps> = (
  props: EmailShareProps,
) => {

  const Icon = ShareIcon(EmailIcon);

  function getBody() {
    let comments = '';
    props.evangeliSocialShareData.comments.map(
      (commentSocialShareData) => {
        const verse = commentSocialShareData.verse ? commentSocialShareData.verse + "\n\n" : '';
        comments += '---------------------' + "\n\n" + verse + commentSocialShareData.comment_text + "\n\n";
      }
    );

    return props.evangeliSocialShareData.gospel_text + "\n\n" + comments;
  }

  function getSubject() {
    return props.evangeliSocialShareData.comments[0]
      ?
      props.evangeliSocialShareData.comments[0].social_text
      :
      props.evangeliSocialShareData.gospel_title;
  }

  return (
    <EmailShareButton
      url={props.evangeliSocialShareData.url}
      subject={getSubject()}
      body={getBody()}>
        <Icon />
    </EmailShareButton>
  );
}

export default EmailShare;
