import React from "react";
import {WhatsappShareButton, WhatsappIcon} from "react-share";
import ShareIcon from "./ShareIcon";
import EvangeliSocialShareData from "../../models/evangeli/EvangeliSocialShareData";

interface WhatsappShareProps {
  evangeliSocialShareData: EvangeliSocialShareData;
}

const WhatsappShare: React.FC<WhatsappShareProps> = (
  props: WhatsappShareProps,
) => {

  const Icon = ShareIcon(WhatsappIcon);

  function getBody() {

    const social_text = props.evangeliSocialShareData.comments[0] && props.evangeliSocialShareData.comments[0].social_text
      ?
      props.evangeliSocialShareData.comments[0].social_text
      :
      props.evangeliSocialShareData.gospel_title;

    const verse = props.evangeliSocialShareData.comments[0] && props.evangeliSocialShareData.comments[0].verse
      ?
      props.evangeliSocialShareData.comments[0].verse + "\n\n"
      :
      '';

    return social_text + "\n\n" + verse;
  }

  return (
    <WhatsappShareButton
      url={props.evangeliSocialShareData.url}
      title={getBody()}
    >
        <Icon />
    </WhatsappShareButton>
  );
}

export default WhatsappShare;
